import { isProd } from "./index"

export function getFrontendAppUrl() {
  return isProd() ? "https://app.prowess.app" : "http://localhost:3000"
}

export function getBackendGraphqlUrl() {
  return isProd()
    ? "https://prowess-backend.herokuapp.com/graphql"
    : "http://localhost:4000/graphql"
}
