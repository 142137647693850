import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import styled from "@emotion/styled"
import { Link, graphql, navigate } from "gatsby"
import { getFrontendAppUrl, getBackendGraphqlUrl } from "../utils/urls"
import useWindowSize from "../hooks/useWindowSize"

function isEmail(text: string) {
  if (
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      text
    )
  ) {
    return true
  }
  return false
}

function sendSlackMessage(message: string) {
  fetch(
    "https://hooks.slack.com/services/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",
    {
      method: "POST",
      headers: {},
      body: JSON.stringify({
        text: message,
      }),
    }
  )
}

const DesktopLandingPage = ({ data }: any) => {
  const [email, setEmail] = React.useState("")
  const [error, setError] = React.useState("")

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (email === "" || !isEmail(email)) {
      setError("Please enter a valid email address.")
      return
    }
    setError("")
    // otherwise submit it
    fetch(getBackendGraphqlUrl(), {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: `
          mutation {
            requestAccess(input: { email: "${email}"}) {
              error {
                message
              }
            }
          }
        `,
      }),
    })
      .then(() => {
        sendSlackMessage("New lead: " + email)
        navigate("/success")
      })
      .catch(e => {
        sendSlackMessage("SUBMIT FAILED! " + email)
        setError(
          "Oops something went wrong! Please try again or email me directly at eric@prowess.app"
        )
      })
  }

  return (
    <Layout>
      <SEO title="Prowess" />
      <HeroContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: 1200,
            padding: "40px 20px 0px",
            width: "100%",
          }}
        >
          <NavBar>
            <Img
              className="logo"
              fixed={data.logo.childImageSharp.fixed}
              alt="headshot"
            />
            <div style={{ paddingLeft: 8 }}>Prowess</div>
          </NavBar>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <HeroMainText>Make your product feedback more useful</HeroMainText>
            <HeroSubText>
              Collect feedback from your team directly in Slack. Use your own
              customer data to make better decisions.
            </HeroSubText>
            {error !== "" && <div style={{ color: "#EC7965" }}>{error}</div>}
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                width: "100%",
                maxWidth: 500,
                justifyContent: "center",
              }}
            >
              <EmailInput
                value={email}
                onChange={e => setEmail(e.target.value)}
                type="email"
                placeholder="Your work email"
              ></EmailInput>
              <StartButton onClick={handleSubmit}>Join Beta</StartButton>
            </form>
            <HeroSubSubText>
              We're currently onboarding new users personally to ensure a great
              experience. We'll help you get set up, import your data, and more!
            </HeroSubSubText>
          </div>
        </div>
      </HeroContainer>
      <ContentContainer>
        <PaddedContainer>
          <ContentText>
            <h2>Built for Slack</h2>
            <p style={{ color: "#4E5272" }}>
              Your team wants to submit customer feedback through Slack, not
              Trello or Google Sheets. Our intelligent Slack bot allows your
              entire team to easily contribute feedback, saving you bandwidth.
            </p>
          </ContentText>
          <ContentImage>
            <Img
              className="logo"
              fluid={data.slack.childImageSharp.fluid}
              alt="headshot"
            />
          </ContentImage>
        </PaddedContainer>
      </ContentContainer>
      <ContentContainer>
        <PaddedContainer>
          <ContentText>
            <h2>Leverage your customer data</h2>
            <p style={{ color: "#4E5272" }}>
              If customers are mentioned with product feedback, our integrations
              can pull in relevant data from your CRM and other sources.
              Prioritize features based on their impact to revenue, # of
              enterprise customers, and more.
            </p>
          </ContentText>
          <ContentImage>
            <Img
              className="logo"
              fluid={data.connections.childImageSharp.fluid}
              alt="headshot"
            />
          </ContentImage>
        </PaddedContainer>
      </ContentContainer>
      <ContentContainer>
        <PaddedContainer>
          <ContentText>
            <h2>Prioritize</h2>
            <p style={{ color: "#4E5272" }}>
              Sort, filter, and organize the data however you want.
            </p>
          </ContentText>
          <ContentImage>
            <Img
              className="logo"
              fluid={data.prioritize.childImageSharp.fluid}
              alt="headshot"
            />{" "}
          </ContentImage>
        </PaddedContainer>
      </ContentContainer>
      <ContentContainer>
        <PaddedContainer>
          <ContentText>
            <h2>Use the same tools</h2>
            <p style={{ color: "#4E5272" }}>
              Use JIRA or another task-tracking tool? Easily create issues from
              Prowess feedback or link them to existing epics, and we'll help
              you track the overall progress of a feature in development.
            </p>
          </ContentText>
          <ContentImage style={{ width: "594px" }}>
            <Img
              className="logo"
              fluid={data.taskmanagement.childImageSharp.fluid}
              alt="headshot"
            />{" "}
          </ContentImage>
        </PaddedContainer>
      </ContentContainer>
      <ContentContainer>
        <PaddedContainer>
          <ContentText>
            <h2>Close the loop</h2>
            <p style={{ color: "#4E5272" }}>
              Prowess remembers all customers + stakeholders who care about each
              feature. When a feature is done, send everyone a personal message
              via Slack!
            </p>
          </ContentText>
          <ContentImage>
            <Img
              className="logo"
              fluid={data.closetheloop.childImageSharp.fluid}
              alt="headshot"
            />{" "}
          </ContentImage>
        </PaddedContainer>
      </ContentContainer>
      <ContentContainer>
        <PaddedContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <HeroSubText>Want Prowess?</HeroSubText>
            {error !== "" && <div style={{ color: "#EC7965" }}>{error}</div>}
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                width: "100%",
                maxWidth: 500,
                justifyContent: "center",
              }}
            >
              <EmailInput
                value={email}
                onChange={e => setEmail(e.target.value)}
                type="email"
                placeholder="Your work email"
              ></EmailInput>
              <StartButton onClick={handleSubmit}>Join Beta</StartButton>
            </form>
          </div>
        </PaddedContainer>
      </ContentContainer>
      <ContentContainer>
        <PaddedContainer>
          <div
            dangerouslySetInnerHTML={{
              __html: `<a href="https://www.producthunt.com/posts/prowess?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-prowess" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=206588&theme=light" alt="Prowess - The simplest way to collect and leverage product feedback | Product Hunt Embed" style="width: 250px; height: 54px;" width="250px" height="54px" /></a>`,
            }}
          ></div>
        </PaddedContainer>
      </ContentContainer>

      <ContentContainer>
        <PaddedContainer>
          <div
            style={{
              borderTop: "1px solid #E4EBF6",
              paddingTop: 24,
              paddingBottom: 24,
              width: "100%",
              display: "flex",
            }}
          >
            <div>Prowess</div>
            <div style={{ marginLeft: "auto", display: "flex" }}>
              <div style={{ paddingRight: 8 }}>
                <Link to="/privacy">Privacy</Link>
              </div>
              <Link to="/terms">Terms of Service</Link>
            </div>
          </div>
        </PaddedContainer>
      </ContentContainer>
    </Layout>
  )
}

const MobileLandingPage = ({ data }: any) => {
  const [email, setEmail] = React.useState("")
  const [error, setError] = React.useState("")

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (email === "" || !isEmail(email)) {
      setError("Please enter a valid email address.")
      return
    }
    setError("")
    // otherwise submit it
    fetch(getBackendGraphqlUrl(), {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: `
          mutation {
            requestAccess(input: { email: "${email}"}) {
              error {
                message
              }
            }
          }
        `,
      }),
    })
      .then(() => {
        sendSlackMessage("New lead: " + email)
        navigate("/success")
      })
      .catch(e => {
        sendSlackMessage("SUBMIT FAILED! " + email)
        setError(
          "Oops something went wrong! Please try again or email me directly at eric@prowess.app"
        )
      })
  }
  return (
    <Layout>
      <SEO title="Prowess" />
      <HeroContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "40px 20px 0px",
            width: "100%",
          }}
        >
          <NavBar>
            <Img
              className="logo"
              fixed={data.logo.childImageSharp.fixed}
              alt="headshot"
            />
            <div style={{ paddingLeft: 8 }}>Prowess</div>
          </NavBar>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <HeroMainText>Make your product feedback more useful</HeroMainText>
            <HeroSubText>
              Collect feedback from your team directly in Slack. Use your own
              customer data to make better decisions.
            </HeroSubText>
            {error !== "" && <div style={{ color: "#EC7965" }}>{error}</div>}
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                width: "100%",
                maxWidth: 500,
                justifyContent: "center",
              }}
            >
              <EmailInput
                value={email}
                onChange={e => setEmail(e.target.value)}
                type="email"
                placeholder="Your work email"
              ></EmailInput>
              <StartButton onClick={handleSubmit}>Join Beta</StartButton>
            </form>
            <HeroSubSubText>
              We're currently onboarding new users personally to ensure a great
              experience. We'll help you get set up, import your data, and more!
            </HeroSubSubText>
          </div>
        </div>
      </HeroContainer>
      <ContentContainer>
        <PaddedContainer>
          <ContentText>
            <h2 style={{ textAlign: "center" }}>Built for Slack</h2>
            <p style={{ color: "#4E5272", textAlign: "left" }}>
              Your team wants to submit customer feedback through Slack, not
              Trello or Google Sheets. Our intelligent Slack bot allows your
              entire team to easily contribute feedback, saving you bandwidth.
            </p>
          </ContentText>
          <ContentImage>
            <Img
              className="logo"
              fluid={data.slack.childImageSharp.fluid}
              alt="headshot"
            />
          </ContentImage>
        </PaddedContainer>
      </ContentContainer>
      <ContentContainer>
        <PaddedContainer>
          <ContentText>
            <h2 style={{ textAlign: "center" }}>Leverage your customer data</h2>
            <p style={{ color: "#4E5272", textAlign: "left" }}>
              If customers are mentioned with product feedback, our integrations
              can pull in relevant data from your CRM and other sources.
              Prioritize features based on their impact to revenue, # of
              enterprise customers, and more.
            </p>
          </ContentText>
          <ContentImage>
            <Img
              className="logo"
              fluid={data.connections.childImageSharp.fluid}
              alt="headshot"
            />
          </ContentImage>
        </PaddedContainer>
      </ContentContainer>
      <ContentContainer>
        <PaddedContainer>
          <ContentText>
            <h2 style={{ textAlign: "center" }}>Prioritize</h2>
            <p style={{ color: "#4E5272", textAlign: "left" }}>
              Sort, filter, and organize the data however you want.
            </p>
          </ContentText>
          <ContentImage>
            <Img
              className="logo"
              fluid={data.prioritize.childImageSharp.fluid}
              alt="headshot"
            />
          </ContentImage>
        </PaddedContainer>
      </ContentContainer>
      <ContentContainer>
        <PaddedContainer>
          <ContentText>
            <h2 style={{ textAlign: "center" }}>Use the same tools</h2>
            <p style={{ color: "#4E5272", textAlign: "left" }}>
              Use JIRA or another task-tracking tool? Easily create issues from
              Prowess feedback or link them to existing epics, and we'll help
              you track the overall progress of a feature in development.
            </p>
          </ContentText>
          <ContentImage>
            <Img
              className="logo"
              fluid={data.taskmanagement.childImageSharp.fluid}
              alt="headshot"
            />
          </ContentImage>
        </PaddedContainer>
      </ContentContainer>
      <ContentContainer>
        <PaddedContainer>
          <ContentText>
            <h2 style={{ textAlign: "center" }}>Close the loop</h2>
            <p style={{ color: "#4E5272", textAlign: "left" }}>
              Prowess remembers all customers + stakeholders who care about each
              feature. When a feature is done, send everyone a personal message
              via Slack!
            </p>
          </ContentText>
          <ContentImage>
            <Img
              className="logo"
              fluid={data.closetheloop.childImageSharp.fluid}
              alt="headshot"
            />
          </ContentImage>
        </PaddedContainer>
      </ContentContainer>
      <ContentContainer>
        <PaddedContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <HeroSubText>Want Prowess?</HeroSubText>
            {error !== "" && <div style={{ color: "#EC7965" }}>{error}</div>}
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                width: "100%",
                maxWidth: 500,
                justifyContent: "center",
              }}
            >
              <EmailInput
                value={email}
                onChange={e => setEmail(e.target.value)}
                type="email"
                placeholder="Your work email"
              ></EmailInput>
              <StartButton onClick={handleSubmit}>Join Beta</StartButton>
            </form>
          </div>
        </PaddedContainer>
      </ContentContainer>
      <ContentContainer>
        <PaddedContainer>
          <div
            dangerouslySetInnerHTML={{
              __html: `<a href="https://www.producthunt.com/posts/prowess?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-prowess" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=206588&theme=light" alt="Prowess - The simplest way to collect and leverage product feedback | Product Hunt Embed" style="width: 250px; height: 54px;" width="250px" height="54px" /></a>`,
            }}
          ></div>
        </PaddedContainer>
      </ContentContainer>

      <ContentContainer>
        <PaddedContainer>
          <div
            style={{
              borderTop: "1px solid #E4EBF6",
              paddingTop: 24,
              paddingBottom: 24,
              width: "100%",
              display: "flex",
            }}
          >
            <div>Prowess</div>
            <div style={{ marginLeft: "auto", display: "flex" }}>
              <div style={{ paddingRight: 8 }}>
                <Link to="/privacy">Privacy</Link>
              </div>
              <Link to="/terms">Terms of Service</Link>
            </div>
          </div>
        </PaddedContainer>
      </ContentContainer>
    </Layout>
  )
}

const IndexPage = (props: any) => {
  const { width, height } = useWindowSize()

  if (width == null || width > 1000) {
    return <DesktopLandingPage {...props}></DesktopLandingPage>
  } else {
    return <MobileLandingPage {...props}></MobileLandingPage>
  }
}

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 72px;
`

const PaddedContainer = styled.div`
  display: flex;
  max-width: 1200px;
  padding: 0 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`

const ContentText = styled.div`
  flex: 1;
  padding-right: 12px;
  @media only screen and (max-width: 1000px) {
  }
`

const ContentImage = styled.div`
  flex: 1;
  @media only screen and (max-width: 1000px) {
    flex: none;
    max-width: 100%;
    width: 100%;
  }
`

const EmailInput = styled.input`
  padding: 20px;
  outline: none;
  border-top: 1px solid #bfa9ed;
  border-left: 1px solid #bfa9ed;
  border-bottom: 1px solid #bfa9ed;
  border-radius: 8px 0 0 8px;
  border-right: none;
  font-size: 20px;
  flex: 3;
  @media only screen and (max-width: 1000px) {
    padding: 8px 16px;
    font-size: 14px;
  }
`

const StartButton = styled.div`
  flex: 1;
  cursor: pointer;
  transition: background 120ms ease-in 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 0 8px 8px 0;
  background-color: rgb(149, 126, 197);
  color: white;
  font-weight: 800;
  font-size: 20px;
  line-height: 20px;
  padding: 16px 24px;
  &:hover {
    background-color: rgb(107, 121, 206);
  }
  @media only screen and (max-width: 1000px) {
    padding: 8px 12px;
    font-size: 14px;
  }
`

const HeroContainer = styled.div`
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    min-height: 0;
    padding-bottom: 40px;
  }
`

const NavBar = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
`

const HeroMainText = styled.h1`
  color: black;
  padding-top: 180px;
  font-size: 54px;
  font-weight: 800;
  text-align: center;
  max-width: 800px;
  width: 70%;
  @media only screen and (max-width: 1000px) {
    padding-top: 72px;
    font-size: 40px;
    width: 100%;
  }
`

const HeroSubText = styled.h1`
  font-weight: 400;
  line-height: 28px;
  padding-top: 12px;
  max-width: 600px;
  font-size: 20px;
  color: #7f8297;
  text-align: center;
  width: 80%;
  @media only screen and (max-width: 1000px) {
    font-size: 16px;
    width: 100%;
  }
`

const HeroSubSubText = styled.h1`
  padding-top: 12px;
  text-align: center;
  font-size: 14px;
  color: #7f8297;
  font-weight: 400;
  @media only screen and (max-width: 1000px) {
    font-size: 16px;
    width: 100%;
    font-size: 12px;
  }
`

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "favicon.png" }) {
      childImageSharp {
        fixed(width: 30, height: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    slack: file(relativePath: { eq: "slack.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 594, maxHeight: 430, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    connections: file(relativePath: { eq: "connections.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 594, maxHeight: 430, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prioritize: file(relativePath: { eq: "prioritize.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 594, maxHeight: 430, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    taskmanagement: file(relativePath: { eq: "taskmanagement.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    closetheloop: file(relativePath: { eq: "closetheloop.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
